/*
Eli siinä vaiheessa että laskuri käy jos nämä ovat true. Eka laittaa tekstin esiin, ja toinen aloittaa laskurin...sit voi puhua ja napista valmis.
Eli katso mitä tapahtuu kun painaa siitä napista...


const [firstCount, setFirstCount] = useState(false);
const [isOpen, setIsOpen] = useState(false);
*/


import './App.css';
import { useEffect, useRef, useState } from 'react'
import Main from "./Main";
import Fit from "./Fit";
import Mot from "./Mot";
import Ind from "./Ind";
import Volume from "./Volume";
import FeedbackModal from "./feedback";
import Modal from 'react-modal';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import ResponseCounter from "./counter"
import Logopikku from "./logopikku.png";
import { AiOutlineCopy } from 'react-icons/ai';
import { VscFeedback} from 'react-icons/vsc';
import Nav from "./navbar";
import { AiOutlineAudio,AiOutlineAudioMuted } from "react-icons/ai";
import { isMobile, isTablet } from 'react-device-detect';

function Simulator3({lan, kre, que, haa, ema, own, man, tim}) {


 
   const {
    transcript,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [supportState, setSupportState] = useState(true);
  const letGoogleApi = useRef(false);
  
  useEffect(() => {
    if (!browserSupportsSpeechRecognition || isMobile || isTablet) {
      setSupportState(false);
      
    }
  }, [browserSupportsSpeechRecognition]);
  
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const startTalking = useRef(false);
  const [startCounter, setStartCounter] = useState([
    "20", "19", "18", "17", "16", "15", "14", "13", "12", "11", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1", "Start Talking"
  ]);
  const [firstCount, setFirstCount] = useState(false);
  const [time, setTime] = useState(tim);

  const [secondCount, setSecondCount] = useState(false);
  const [countdown, setCountdown] = useState(time);
  const [timesUp, setTimesUp]= useState(false);
  const question = useRef("");
  const [count, setCount] = useState(0);
  const [analyzing, setAnalyzing] = useState(false);
  const [chatgpt, setChatgpt] = useState("");
  const [buttonText, setButtonText] = useState("Ready");  
  const [tryAgain, setTryAgain] = useState(false);
  const [trya, setTrya] = useState("Try again?");  
  const [kreditifinitto, setKredititFinitto] = useState(false);
  const [kredits, setKredits] = useState(kre);
  const [kysymykset, setKysymykset] = useState(que); //kyssärit
  const [vastaukset, setVastaukset] = useState([]); //kyssärit
  const [vuoro, setVuoro] = useState(0); //kyssärit
  const [vastatut, setVastatut] = useState(0); //kyssärit
  const [haastattelu, setHaastattelu] = useState(haa); //kyssärit
  const valmis = useRef(false); //kyssärit
  const [valmis2, setValmis2] = useState(false); //kyssärit
  const [owner, setOwner] = useState(own);
  const [user, setUser] = useState(ema);
  const [mandaatti, setMandaatti] = useState(man);
  const [speechLang, setSpeechLang] = useState ("fi-FI");
  const [seuraavaNappi, setseuraavaNappi] = useState ("");
  const [valmisTeksi, setvalmisTeksi] = useState ("");
  const [HaastatteluvalmisTeksi, setHaastattelVvalmisTeksi] = useState ("");
  const [heippateksti, setHeippateksti] = useState ("");
  const [heippateksti2, setHeippateksti2] = useState ("");
  const [odota, setOdota] = useState(false);


  const abortControllerRef = useRef(null);

  

  //Äänijuttuja-------------------------------------------------------------------
  const [recording, setRecording] = useState(false);
  const mediaRecorder = useRef(null);
  let recordedChunks = [];

  const requestMicrophonePermission = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
  
      if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
        return true;
      } else {
        console.error('Mikrofonin käyttölupa evätty');
        return false;
      }
    } catch (err) {
      console.error('Virhe mikrofonin käyttöluvan pyytämisessä:', err);
      return false;
    }
  };

  const showPermissionInstructions = () => {
    handleCloseModal();
    alert('Microphone access denied. Open your browser settings and grant permission for microphone use on this site.');

  };
 
 const startRecording = async () => {
  
  
  const permissionGranted = await requestMicrophonePermission();

  if (!permissionGranted) {
    showPermissionInstructions();
    return;
  }

  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  mediaRecorder.current = new MediaRecorder(stream);

  mediaRecorder.current.ondataavailable = (event) => {
    if (event.data.size > 0) {
      recordedChunks.push(event.data);
    }
  };

  mediaRecorder.current.onstop = () => {
    const blob = new Blob(recordedChunks, { type: 'audio/webm;codecs=opus' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = async () => {

      const base64data = reader.result.split(',')[1];
      const audioUrl = URL.createObjectURL(blob);
      const audio = new Audio(audioUrl);
      
      const AudioContextConstructor = window.AudioContext || window['webkitAudioContext'];
      const audioContext = new AudioContextConstructor();
      const sampleRate = audio.mozSampleRate || audio.webkitSampleRate || audioContext.sampleRate;
     
      if (letGoogleApi.current){   
        sendAudioToCloudFunction(base64data, sampleRate); // Pass the sample rate to the back-end
      }
       
    };
  };

  mediaRecorder.current.start(10); // Set a timeslice for data collection
  setRecording(true);
};

const stopRecording = async () => {
  if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
    mediaRecorder.current.stop();
    setRecording(false);
  }
};

const sendAudioToCloudFunction = (audio, rate) => {
/*
  fetch('https://us-central1-simudatauser.cloudfunctions.net/api/speech-to-text', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ audio:audio, lan:lan, sampleRate:rate}),
  })

  .then(response => response.text())
  .then(data => {
    handleSend(data);
    //console.log(data);
   
  })
  .catch(error => (console.error('Error:', error), 
            setChatgpt("Oops! It seems like there's an issue with our artificial intelligence. We'll fix the problem promptly. Please try again later."),
            gptIsReady(),
            setAnalyzing(false),
            speech.current=""));*/


  
};

  
 
  const [loadingText, setLoadingText] = useState("Just a sec, I´am building the feedback");
  const speech = useRef("");
  const chatIsReady = useRef(false);

  const [feedback, setFeedback] = useState({ rating: 5, comments: '' });

  const isMicrophoneMuted = useRef();

  const handleRatingChange = (e) => {
    setFeedback({ ...feedback, rating: e.target.value });
  };

  const handleCommentsChange = (e) => {
    setFeedback({ ...feedback, comments: e.target.value });
  };


 
  const handleCloseModal2 = () => {
    setIsOpen2(false);
  }
  const isopen2= () => {
    setIsOpen2(true);
  }

  const kreditfinittofalse= () => {
    setKredititFinitto(false)
  }

  const handleCloseModal = () => {
    startTalking.current = false;
    setIsOpen(false);
    setIsOpen2(false);
    if (!supportState){
      letGoogleApi.current = false;
      stopRecording();
      
    }else{
      SpeechRecognition.stopListening();
    }
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    setTimesUp(false);
    setCountdown(time);
    setCount(0);
    setAnalyzing(false);
    setTryAgain(false);
    setCopied(false);

    if (kredits == 0){
      setKredititFinitto(true);
    }
  }

  const timeIsUp = () => { //Kun ollaan vastattu kysymykseen. Tässä vois olla se pushaus, vai mennäänkö handlesendin kautta...
    
    setSecondCount(false);
    setCountdown(time);
    startTalking.current = false;
    SpeechRecognition.stopListening();
    //setAnalyzing(true); 
   

    if (!supportState){
      letGoogleApi.current = false;
      stopRecording();
      
    }else{
      SpeechRecognition.stopListening();
    }
    
    if (kysymykset.length > (vuoro+1)){//jos kysymyksiä on jäljellä
      setVuoro(vuoro +1);
      getAnswer();
      if (supportState){
        //console.log("Menee handlesendiin");
        handleSend();
      
      }else{
        stopRecording();
      }

    }else {      
      valmis.current = true;
      if (supportState){
        handleSend();
      
      }else{
        stopRecording();
      }
    }
   
  }
  const [speechLanguage, setSpeechLanguage] = useState("");

  useEffect(() => {
    
    if(speechLang != ""){
      setSpeechLanguage(speechLang)
    }
    
   }, [speechLang]); 



  function getAnswer(q) {
    //tähän tullaan kun haastattelu alkaa tai kun kyssäri vaihtuu
    
    if (vuoro === 0 ||(kysymykset.length > (vuoro+1))){ //jos kysymyksiä on jäljellä
      chatIsReady.current = false;
      setSecondCount(false);
      setIsOpen(true);
      setCount(0); 
      question.current = q;
      setChatgpt("");  
    }else{
     //Tähän functiokutsu joka hakee countit, ja palauttaa ne
     //loadCredits(q);
      setKredititFinitto(true); //Haastattelu on loppu 
      setHaastattelu(false); 
    }
    //changeCredits("red");
  }


  useEffect(() => {
  if (vuoro === 0 || (haastattelu && !valmis.current)) {
    getAnswer(kysymykset[vuoro]);
  }
}, [haastattelu, vuoro]);

   useEffect(() => {
    
  

   }, [transcript]); 


  const [copied, setCopied] = useState(false)
  const handleCopy = () => {
    navigator.clipboard.writeText(chatgpt);
    setCopied(true);
  };

  const gptIsReady = () => {  
    chatIsReady.current = true;
  }

  const changeAnalyzing = () => {
    setAnalyzing(false);
    chatIsReady.current = false;
    setTryAgain(true);
  }

  function deleteCookie() {
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('credits');
    window.location.reload();
  }
  function homepage(){
    window.location.reload();
  }

  useEffect(() => {
    let intervalId;
    if (count < 21 && isOpen === true && startTalking.current === false ){  
      setTimesUp(false);
      setFirstCount(true);
      intervalId = setInterval(() => {
        setCount(count => count + 1);   
      }, 1000);
    }else if (count === 21){
     
      //eli tähän se nauhoittaminen

      if (supportState){
        resetTranscript();
        speech.current="";
        SpeechRecognition.startListening({ continuous: true, language: speechLanguage }); 
      }else {
        letGoogleApi.current = true;
      }
      startTalking.current = true;  
      setFirstCount(false); 
      setSecondCount(true);
      if (!supportState){
        startRecording();
      } 
    }
    
    return () => clearInterval(intervalId); 
  }, [isOpen, count]); 

  useEffect(() => {
    if (startTalking.current){
      speech.current = transcript;
    }
   }, [transcript]); 


   useEffect(() => {
    let intervalId;
    if (countdown > 0 && startTalking.current === true && secondCount){
      intervalId = setInterval(() => {
        setCountdown(countdown => countdown - 1);     
      }, 1000);
    }else if (countdown === 0){   
      timeIsUp();    
    }
    if (countdown === 10){
      setTimesUp(true);
    }
    
    return () => clearInterval(intervalId);
  }, [countdown,startTalking.current]); 

  useEffect(() => {

    if (lan === "Suomi"){
      setStartCounter(["20", "19", "18", "17", "16", "15", "14", "13", "12", "11", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1","Aloita puhuminen"]);
      setButtonText("Olen valmis");
      setLoadingText("Hetki pieni, kirjoitan palautetta...");
      setTrya("Kokeile uudestaan?");
      setSpeechLang("fi-FI");
      setseuraavaNappi("Seuraava kysymys");
      setvalmisTeksi("Valmis");
      setHeippateksti("Kiitos, että vastasit kysymyksiimme!");
      setHeippateksti2("Konsulttimme käyvät vastauksesi läpi ja ottavat sinuun yhteyttä pian.");
      setHaastattelVvalmisTeksi("Haastattelu on valmis!");
      
    }
    else if (lan === "Englanti"){
      setStartCounter(["20", "19", "18", "17", "16", "15", "14", "13", "12", "11", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1","Start Talking"]);
      setButtonText("I´m ready");
      setLoadingText("Just a sec, I´m building the feedback...");
      setTrya("Try again?");
      setSpeechLang("en-EN");
      setseuraavaNappi("Next question");
      setvalmisTeksi("Ready");
      setHeippateksti("Thank you for answering our questions!");
      setHeippateksti2("Our consultants will review your answers and get in touch with you soon.");
      setHaastattelVvalmisTeksi("The interview is complete!");

      
    }
    else if (lan === "Ruotsi"){


      setStartCounter(["20", "19", "18", "17", "16", "15", "14", "13", "12", "11", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1", "Börja prata"]);
      setButtonText("Jag är redo");
      setLoadingText("Ett ögonblick, jag sammanställer återkopplingen...");
      setTrya("Försöka igen?");
      setSpeechLang("sv-SE");
      setseuraavaNappi("Nästa fråga");
      setvalmisTeksi("Redo");
      setHeippateksti("Tack för att du svarade på våra frågor!");
      setHeippateksti2("Våra konsulter kommer att granska dina svar och kontakta dig snart.");
      setHaastattelVvalmisTeksi("Intervjun är klar!");

      
    }
  
  }, [lan]); 
  
    function handleSend(text) {  
      if (text) { // Jos tullaan Googlen kautta
        setVastaukset((vastaukset) => {
          const updatedVastaukset = [...vastaukset, text];
          fetsaus(updatedVastaukset); // Välitetään päivitetty taulukko fetsaus-funktioon
          return updatedVastaukset;
        });
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;
      } else { // Ei tulla Googlen kautta
        setVastaukset((vastaukset) => {
          const updatedVastaukset = [...vastaukset, transcript]; // Varmista, että 'transcript' on määritelty
          fetsaus(updatedVastaukset); // Välitetään päivitetty taulukko fetsaus-funktioon
          return updatedVastaukset;
        });
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;
      }
    }

    const fetsaus = async (text) => {

      if(valmis.current){
        setValmis2(true);
     
        fetch('https://us-central1-simudatauser.cloudfunctions.net/api/korjaus', {
          //fetch('http://localhost:5001/simudatauser/us-central1/api/korjaus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ vastaukset: text, kysymykset:kysymykset, owner:owner, email:user,mandaatti:mandaatti })   
        })

        .then(response => response.json())
        .then(data => { 
          if (data.status == "ok" ){
        
            setValmis2(false);
            
          }else{
           
          }
            
        }) 
        .catch(error => console.error(error));  
      }
    }
    
    

   
    useEffect(() => {
      sessionStorage.setItem('credits', kredits.toString());
    }, [kredits]);
    
    useEffect(() => {
      const creditsFromSessionStorage = sessionStorage.getItem('credits');
      if (creditsFromSessionStorage) {
        setKredits(Number(creditsFromSessionStorage));
      }
    }, []);

    useEffect(() => {
      let audioTrack;
      let muteHandler = function() {
  
        isMicrophoneMuted.current = true;
      };
      let unmuteHandler = function() {
      
        isMicrophoneMuted.current = false;
      };
    
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(function(stream) {
          audioTrack = stream.getAudioTracks()[0];
    
          if (audioTrack && !audioTrack.muted) {
            unmuteHandler();
          } else {
            muteHandler();
          }
    
          audioTrack.addEventListener('mute', muteHandler);
          audioTrack.addEventListener('unmute', unmuteHandler);
        })
        .catch(function(error) {
          console.log('Virhe:', error);
        });
    
      return () => {
        if (audioTrack) {
          audioTrack.removeEventListener('mute', muteHandler);
          audioTrack.removeEventListener('unmute', unmuteHandler);
        }
      };
    }, []);

  return (
    <div style={{textAlign:"center"}}>
       {!valmis.current && <h3 style={{maxWidth:"90%", marginLeft:"auto", marginRight:"auto", marginTop:"3vh"}}>{kysymykset[vuoro]}</h3>}
       
       {valmis.current && !valmis2 && <div style={{ textAlign: 'center', paddingTop: '20px' }}>
              <h3 style={{ maxWidth: "90%", marginLeft: "auto", marginRight: "auto", marginTop: "6vh", marginBottom: "10vh" }}>
                {HaastatteluvalmisTeksi}
              </h3>
              <p>{heippateksti}</p>
              <p>{heippateksti2}</p>
                      <Button
                  className='SignIn'
                  variant="primary"
                  type="submit"
                  style={{marginTop:"0vh", width:"40%", marginRight:"auto", marginLeft:"auto", marginTop:"15vh"}}
                  onClick={(e) => {
                    window.location.reload();  // Sivun lataaminen uudelleen
                  }}
                >
                  Sulje
                </Button>
    
            </div>}

        {valmis.current && valmis2 && 

            <div style={{ textAlign: "center" }}>
                <img className="logopikku" src={Logopikku} alt="kuva" />
                <div>
                    <p>Loading...</p>
                </div>
            </div>
       }
     
   
  
       {firstCount && <div className={count < 20 ? "CountBlock2" : "TalkingBlock2"}><p><span className={count < 20 ? "Count2" : "Talking2"}>{startCounter[count]}</span></p></div>}
        {startTalking.current && <div ><span style={{fontSize:"4em"}}>{isMicrophoneMuted.current ?<AiOutlineAudioMuted/>:<AiOutlineAudio/>}</span></div>}
        {startTalking.current && <Volume lan={"fi"} />}
        {secondCount && <div><p style={timesUp ?{ fontSize:"3em", color:"red"}:{ fontSize:"3em", color:"black", margin:"0%", marginBottom:"5vh"} }>{countdown}</p></div> }
        {startTalking.current && (
          <button
            className='button10'
            onClick={() => timeIsUp()}
          >
            {(vuoro+1) < kysymykset.length ? seuraavaNappi : valmisTeksi}
          </button>
        )}
         
       <div 
            style={{
              position: 'fixed',
              bottom: '0',
              left: '0',
              right: '0',
              padding: '10px',
              backgroundColor: 'white',
              boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
              zIndex: 1000,
            }}
          >
             
          
          </div>
   
    </div>
  );
 

}

export default Simulator3;

