import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Logo from './logo.png'; // Muista vaihtaa polku logoosi
import { Navbar, Nav } from 'react-bootstrap';
import { VscFeedback, VscSignOut } from "react-icons/vsc";

const ConAdd = ({ lan, kon }) => {
    const [konsultit, setKonsultit] = useState([]); // Konsultit taulukko
    const [konsultit2, setKonsulttiLista] = useState(kon);
    const [nimi, setNimi] = useState(''); // Nimi kenttä
    const [group, setGroup] = useState(''); // Group kenttä
    const [email, setEmail] = useState(''); // Email kenttä
    const [salasana, setSalasana] = useState(''); // Salasana kenttä
    const [salasanaVahvistus, setSalasanaVahvistus] = useState(''); // Vahvistuskenttä
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (group === "") {
            const currentUrl = window.location.href;
            const parsedUrl = new URL(currentUrl);
            const pathParts = parsedUrl.pathname.split('/');
            if (pathParts.length > 1) {
                setGroup(pathParts[1]); // tallennetaan "tcg" tilaan
            }
        }
    
    }, [group]);

    //nappi josta pois
    function homepage(){
        window.location.reload();
      }

    // Tallenna uusi konsultti
    const tallenna = (e) => {
        e.preventDefault();
        if (salasana !== salasanaVahvistus) {
            alert("Salasanat eivät täsmää!");
            return;
        }

        const uusiKonsultti = {
            nimi,
            email,
            salasana,
        };

        setKonsultit([...konsultit, uusiKonsultti]);
        setNimi('');
        setEmail('');
        setSalasana('');
        setSalasanaVahvistus('');
    };

    const saveCons = async (event) => {
        event.preventDefault();
        if (salasana !== salasanaVahvistus) {
            alert("Salasanat eivät täsmää!");
            return;
        }
        
        setIsLoading(true);
           fetch('https://us-central1-simudatauser.cloudfunctions.net/api/saveConsults', {
            //fetch('http://localhost:5001/simudatauser/us-central1/api/saveConsults', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email, pass: salasana, id: group, name: nimi })
        })
        .then(response => response.json())
        .then(data => { 
            setIsLoading(false);
            setKonsulttiLista(data);
            setNimi("");
            setSalasana("");
            setSalasanaVahvistus("");
            setEmail("");
        })
        .catch(error => console.error(error));  
    };

    return (
        <div>
            {/* Navigation Icon Container */}
            <div style={{ position: "absolute", top: "5vh", right: "10vh", zIndex: 1000 }}>
                <Nav.Link onClick={() => homepage()} className="d-none d-md-block">
                    <VscSignOut style={{ fontSize: "1.9em" }} />
                </Nav.Link>
            </div>
    
            <Container style={{ padding: "10px", height: "95vh" }}>
                <img className="FormLogo" src={Logo} style={{ height: "5vh", marginTop: "1vh" }} alt="Logo" />
                <h1 className="FormTitle" style={{ fontSize: "1.95em", marginBottom: "2vh" }}>Konsultti-lista</h1>
                <Row className="justify-content-start" style={{ width: "100%", height: "70vh" }}>
                    <Col md={4} style={{ overflowY: "auto", height: "100%" }}>
                        <ul>
                            {konsultit2.map((konsultti, index) => (
                                <li key={index}>{konsultti}</li>
                            ))}
                        </ul>
                    </Col>
                    
                    <Col md={4} style={{ display: "flex", justifyContent: "center" }}>
                        <Card className="Card" style={{ width: "90%", height: "80%", margin: "auto" }}>
                            <Card.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                                <Form onSubmit={tallenna}>
                                    <Form.Group>
                                        <Form.Label htmlFor="nimi">Nimi</Form.Label>
                                        <Form.Control
                                            id="nimi"
                                            type="text"
                                            value={nimi}
                                            placeholder="Syötä nimi"
                                            onChange={(e) => setNimi(e.target.value)}
                                            required
                                        />
                                        <Form.Label htmlFor="email" style={{ paddingTop: "1vh" }}>Email</Form.Label>
                                        <Form.Control
                                            id="email"
                                            type="email"
                                            value={email}
                                            placeholder="Syötä sähköposti"
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        <Form.Label htmlFor="salasana" style={{ paddingTop: "1vh" }}>Salasana</Form.Label>
                                        <Form.Control
                                            id="salasana"
                                            type="password"
                                            value={salasana}
                                            placeholder="Syötä salasana"
                                            onChange={(e) => setSalasana(e.target.value)}
                                            required
                                        />
                                        <Form.Label htmlFor="salasanaVahvistus" style={{ paddingTop: "1vh" }}>Vahvista salasana</Form.Label>
                                        <Form.Control
                                            id="salasanaVahvistus"
                                            type="password"
                                            value={salasanaVahvistus}
                                            placeholder="Syötä salasana uudelleen"
                                            onChange={(e) => setSalasanaVahvistus(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                    <div style={{ display: "flex", marginTop: "10vh", justifyContent: "space-between" }}>
                                        <Button
                                            className="SignIn"
                                            variant="primary"
                                            type="button"
                                            style={{ flexGrow: 1, marginLeft: "1%" }}
                                            onClick={(e) => saveCons(e)}
                                        >
                                            Lisää
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
    
};

export default ConAdd;
