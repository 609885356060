import { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button, Dropdown } from 'react-bootstrap';
import Logo from './logo.png'; // Muista vaihtaa polku logoosi
import { Navbar, Nav } from 'react-bootstrap';
import { VscFeedback, VscSignOut } from "react-icons/vsc";
import './App.css';
import Logopikku from "./logopikku.png";
import CryptoJS from 'crypto-js';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';



const ConMan = ({ lan, kon, nam, hak }) => {
    const [konsultit, setKonsultit] = useState([]); // Konsultit taulukko
    const [questions, setQuestions] = useState([]); // Konsultit taulukko
    const [mandaatit, setMandaatit] = useState(kon);
    const [nimi, setNimi] = useState(''); // Nimi kenttä
    const [group, setGroup] = useState(''); // Group kenttä
    const [email, setEmail] = useState(''); // Email kenttä
    const [salasana, setSalasana] = useState(''); // Salasana kenttä
    const [salasanaVahvistus, setSalasanaVahvistus] = useState(''); // Vahvistuskenttä
    const [isLoading, setIsLoading] = useState(false);
    const [ladattu, setLadattu] = useState(false);
    const [valittuMandaatti, setValittuMandaatti] =useState("");
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState(''); // Tila uuden kysymyksen syöttämiseen
    const [customerEmail, setCustomerEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [finalQuestions, setFinalQuestions] = useState([]);
    const [hakijat, setHakijat] =useState([hak]); 
  
    const [done, setDone] =useState([hak]);
    const [gptTitle, setGptTitle] = useState("AI-generoidut");
    const [kieli, setKieli] = useState("");
    const [saving, setSaving] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [selectedTimeValue, setSelectedTimeValue] = useState("");
    const [selectedLevelValue, setSelectedLevelValue] = useState("");

    const [selectedValueTime, setSelectedValueTime] = useState("");
    const [selectedValueLevel, setSelectedValueLevel] = useState("");

    // Avaa modaali
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Sulje modaali
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
   
    try {
            const response = await fetch('https://us-central1-simudatauser.cloudfunctions.net/api/deleteMandate', {
            //const response = await fetch('http://localhost:5001/simudatauser/us-central1/api/deleteMandate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mandaatti: valittuMandaatti, aika:selectedTimeValue, taso:selectedLevelValue })
        });

        const data = await response.json();
        

        // Tarkistetaan, että data on onnistunut
        if (data.status === "ok") {
           //Tähän että lataa sen mandaattilomakkeen näkyviin
        
            setMandaatit(mandaatit.filter(m => m !== valittuMandaatti));
            setLadattu(false);
            setIsModalOpen(false);
            
            // Voit tehdä jotain muuta onnistuneen lähetyksen jälkeen, kuten näyttää ilmoituksen käyttäjälle
        } else {
            console.error('virhe:', data);
            // Voit näyttää virheilmoituksen käyttäjälle tässä
        }
    } catch (error) {
        console.error('Virhe sähköpostin lähetyksessä:', error);
        // Voit näyttää virheilmoituksen käyttäjälle tässä
    }
    


  };


    //const encryptionKey = 'kimi1234'; // Esimerkiksi voit käyttää ympäristömuuttujia
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

    //Fit

    const fitQuestionsUS = ([
        "Tell me about yourself.",
        "What are your greatest strengths?",
        "What are your greatest weaknesses?",
        "Why do you want to work for our company?",
        "What do you know about our company?",
        "Why should we hire you?",
        "What are your career goals?",
        "Where do you see yourself in five years?",
        "How do you handle stress or pressure?",
        "Describe a difficult situation you faced and how you handled it.",
        "Tell me about a time when you had to make a difficult decision.",
        "How do you handle conflict in the workplace?",
        "Describe your leadership style.",
        "What motivates you?",
        "How do you stay organized and manage your time?",
        "Are you a team player?",
        "What is your greatest accomplishment?",
        "Tell me about a time when you failed.",
        "How do you handle criticism?",
        "What do you do when you don´t agree with your boss?",
        "How do you handle competing priorities?",
        "How do you learn from your mistakes?",
        "How do you adapt to change?",
        "What is your ideal work environment?",
        "What type of management style do you prefer?",
        "What is your favorite project you've worked on?",
        "What are your salary expectations?",
        "How do you stay up-to-date in your industry?",
        "Describe a time when you had to work under tight deadlines.",
        "How do you balance work and personal life?",
        "What are your hobbies or interests outside of work?",
        "How do you build relationships with coworkers?",
        "How do you handle office politics?",
        "How do you approach problem-solving?",
        "What do you consider your most significant professional achievement?",
        "Describe a time when you had to persuade someone.",
        "How do you handle unexpected obstacles?",
        "How do you prioritize tasks?",
        "What do you like most about your current job?",
        "What do you dislike most about your current job?",
        "Why are you looking for a new job?",
        "What is your dream job?",
        "How would your coworkers describe you?",
        "How would your boss describe you?",
        "Describe a time when you went above and beyond for a project or client.",
        "What are your expectations for a new role?",
        "How do you handle working with difficult coworkers?",
        "What are your long-term career aspirations?",
        "How do you stay motivated in challenging situations?",
        "Do you have any questions for us?"
    ])

    const fitQuestionsFin = ([
      "Kerro itsestäsi.",
      "Mikä ovat suurimmat vahvuutesi?",
      "Mikä ovat suurimmat heikkoutesi?",
      "Miksi haluat työskennellä yrityksessämme?",
      "Mitä tiedät yrityksestämme?",
      "Miksi meidän pitäisi palkata sinut?",
      "Mitkä ovat uratavoitteesi?",
      "Missä näet itsesi viiden vuoden kuluttua?",
      "Kuinka käsittelet stressiä tai painetta?",
      "Kuvaile vaikeaa tilannetta, jonka kohtasit ja miten selviydyit siitä.",
      "Kerro tilanteesta, jolloin sinun piti tehdä vaikea päätös.",
      "Kuinka käsittelet konflikteja työpaikalla?",
      "Kuvaile johtamistyyliäsi.",
      "Mikä motivoi sinua?",
      "Kuinka pysyt järjestäytyneenä ja hallitset aikaasi?",
      "Oletko tiimipelaaja?",
      "Mikä on suurin saavutuksesi?",
      "Kerro tilanteesta, jossa epäonnistuit.",
      "Kuinka käsittelet kritiikkiä?",
      "Miten toimit, kun et ole samaa mieltä esimiehesi kanssa?",
      "Kuinka käsittelet kilpailevia prioriteetteja?",
      "Miten opit virheistäsi?",
      "Miten sopeudut muutoksiin?",
      "Mikä on ihanteellinen työympäristösi?",
      "Minkä tyyppistä johtamistapaa suosit?",
      "Mikä on suosikki projekti, johon olet osallistunut?",
      "Mitkä ovat palkkatoiveesi?",
      "Miten pysyt ajan tasalla alallasi?",
      "Kuvaile tilannetta, jossa sinun piti työskennellä tiukkojen aikarajojen alla.",
      "Miten tasapainotat työn ja henkilökohtaisen elämän?",
      "Mitkä ovat harrastuksesi tai kiinnostuksenkohteesi työn ulkopuolella?",
      "Miten rakennat suhteita työkavereihin?",
      "Miten käsittelet toimistopolitiikkaa?",
      "Miten lähestyt ongelmanratkaisua?",
      "Mitä pidät merkittävimpänä ammatillisena saavutuksenasi?",
      "Kuvaile tilannetta, jossa jouduit vakuuttamaan jonkun.",
      "Miten käsittelet odottamattomia esteitä?",
      "Miten priorisoit tehtäviä?",
      "Mitä pidät eniten nykyisestä työstäsi?",
      "Mitä pidät vähiten nykyisestä työstäsi?",
      "Miksi etsit uutta työtä?",
      "Mikä on unelmatyösi?",
      "Miten työkaverisi kuvailisivat sinua?",
      "Miten esimiehesi kuvailisi sinua?",
      "Kuvaile tilannetta, jossa menit yli ja edelle projektin tai asiakkaan vuoksi.",
      "Mitkä ovat odotuksesi uudelle roolille?",
      "Miten käsittelet vaikeiden työkavereiden kanssa työskentelyä?",
      "Mitkä ovat pitkän aikavälin uratavoitteesi?",
      "Miten pysyt motivoituneena haastavissa tilanteissa?",
      "Onko sinulla kysyttävää meiltä?",
  ])

  const fitQuestionsSwe = ([
    "Berätta om dig själv.",
    "Vilka är dina största styrkor?",
    "Vilka är dina största svagheter?",
    "Varför vill du arbeta hos oss?",
    "Vad vet du om vårt företag?",
    "Varför ska vi anställa dig?",
    "Vilka är dina karriärmål?",
    "Var ser du dig själv om fem år?",
    "Hur hanterar du stress eller press?",
    "Beskriv en svår situation du har stött på och hur du hanterade den.",
    "Berätta om en situation när du var tvungen att fatta ett svårt beslut.",
    "Hur hanterar du konflikter på arbetsplatsen?",
    "Beskriv din ledarstil.",
    "Vad motiverar dig?",
    "Hur håller du dig organiserad och hanterar din tid?",
    "Är du en lagspelare?",
    "Vad är din största prestation?",
    "Berätta om en situation där du misslyckades.",
    "Hur hanterar du kritik?",
    "Hur agerar du när du inte håller med din chef?",
    "Hur hanterar du konkurrerande prioriteringar?",
    "Hur lär du dig av dina misstag?",
    "Hur anpassar du dig till förändringar?",
    "Hur ser din ideala arbetsmiljö ut?",
    "Vilken typ av ledarstil föredrar du?",
    "Vad är ditt favoritprojekt du har deltagit i?",
    "Vad är dina löneförväntningar?",
    "Hur håller du dig uppdaterad inom ditt område?",
    "Beskriv en situation där du var tvungen att arbeta med strikta tidsfrister.",
    "Hur balanserar du arbete och privatliv?",
    "Vilka är dina intressen eller hobbies utanför arbetet?",
    "Hur bygger du relationer med kollegor?",
    "Hur hanterar du kontorspolitik?",
    "Hur närmar du dig problemlösning?",
    "Vilken anser du vara din största professionella prestation?",
    "Beskriv en situation där du behövde övertyga någon.",
    "Hur hanterar du oväntade hinder?",
    "Hur prioriterar du uppgifter?",
    "Vad gillar du mest med ditt nuvarande jobb?",
    "Vad gillar du minst med ditt nuvarande jobb?",
    "Varför söker du nytt jobb?",
    "Vad är ditt drömjobb?",
    "Hur skulle dina kollegor beskriva dig?",
    "Hur skulle din chef beskriva dig?",
    "Beskriv en situation där du gick utöver det vanliga för ett projekt eller en kund.",
    "Vad har du för förväntningar på en ny roll?",
    "Hur hanterar du att arbeta med svåra kollegor?",
    "Vilka är dina långsiktiga karriärmål?",
    "Hur håller du dig motiverad i utmanande situationer?",
    "Har du några frågor till oss?",
]);

    
    const [fitTitle, setFitTitle] = useState("");

    //mot

    const motQuestionsUS = ([
        "What are some positive aspects of your work?",
        "What´s different about your job now from when you started?",
        "Can you tell me more about what you do at work?",
        "Has anything happened at work recently?",
        "How do your colleagues affect the way you work?",
        "How would you describe your relationship with your colleagues?",
        "Could you tell me about a time when you had a great idea at your job?",
        "What do you do to manage your time efficiently while on the job?",
        "How do your managers help motivate you while you work?",
        "What do you think motivates you most at work?",
        "What brought you here today?",
        "How can I help you feel more comfortable at work?",
        "Why is your work important to you?",
        "What attracted you to your occupation?",
        "What methods of relaxing have helped you previously?",
        "What was it like before you started feeling this way about your work?",
        "What do you think might happen if you don´t make a change?",
        "What´s the best part of your day?",
        "What makes you feel supported at work?",
        "What have you done at work that makes you proud?",
        "How might you improve your work?",
        "What attempts have you already made to improve your situation?",
        "How could changing the way you work make things different?",
        "What changes would help you achieve your goals?",
        "How has your job helped you improve professionally or personally?",
        "Could you describe a time when you discovered a new method or process for doing your work?",
        "What were your goals when seeking this job?",
        "How have your goals changed?",
        "How does the progress you've made so far make you feel?",
        "What inspires you to keep working towards your goals?",
        "How do you maintain a work-life balance?",
        "What strategies do you use to stay focused on your goals?",
        "How do you define success in your current role?",
        "Can you share an example of when you faced a challenge and found motivation to overcome it?",
        "How do you stay motivated when you face criticism or negative feedback?",
        "What activities or hobbies help you recharge outside of work?",
        "Can you describe a situation where you had to motivate a colleague or team member?",
        "How do you seek out opportunities for personal growth and development?",
        "What is your favorite aspect of your job and why?",
        "How do you stay motivated when working on long-term projects?",
        "How do you approach goal-setting, both personally and professionally?",
        "How has your job allowed you to make a positive impact on others?",
        "Can you share a story about a time when you felt particularly motivated or inspired at work?",
        "How do you stay motivated when working in a team with diverse personalities and goals?",
        "How do you handle setbacks or disappointments in your work?",
        "What role does job satisfaction play in keeping you motivated?",
        "How do you approach learning new skills or acquiring new knowledge for your job?",
        "Can you share an example of when you took initiative at work to achieve a positive outcome?",
        "How do you find motivation in situations where the work may be mundane or repetitive?",
        "How do you measure your success and progress in your career?",
    ])

    const motQuestionsFin = ([
      "Mikä on työssäsi positiivista?",
      "Mikä on erilaista työssäsi nyt verrattuna aloittaessasi?",
      "Voisitko kertoa lisää siitä, mitä teet työssäsi?",
      "Onko työssäsi tapahtunut viime aikoina jotain?",
      "Miten kollegasi vaikuttavat työskentelytapaasi?",
      "Miten kuvailisit suhdettasi kollegoihisi?",
      "Voisitko kertoa jonkin kerran, jolloin keksit hienon idean työssäsi?",
      "Mitä teet ajankäytön hallitsemiseksi tehokkaasti työssä?",
      "Miten esimiehesi auttavat motivoimaan sinua työskentelyn aikana?",
      "Mikä mielestäsi motivoi sinua eniten työssä?",
      "Mikä toi sinut tänne tänään?",
      "Kuinka voin auttaa sinua tuntemaan olosi mukavammaksi työssä?",
      "Miksi työsi on sinulle tärkeää?",
      "Mikä houkutteli sinut ammattiisi?",
      "Mitkä rentoutumismenetelmät ovat auttaneet sinua aiemmin?",
      "Miltä sinusta tuntui ennen kuin aloit tuntea näin työstäsi?",
      "Mitä luulet tapahtuvan, jos et tee muutosta?",
      "Mikä on päiväsi paras hetki?","Mikä saa sinut tuntemaan olevasi tuettu työssäsi?",
      "Mitä olet tehnyt työssä, josta olet ylpeä?",
      "Kuinka voisit parantaa työskentelyäsi?",
      "Mitä yrityksiä olet jo tehnyt parantaaksesi tilannettasi?",
      "Kuinka työskentelytapasi muuttaminen voisi tehdä asiat erilaisiksi?",
      "Mitkä muutokset auttaisivat sinua saavuttamaan tavoitteesi?",
      "Kuinka työsi on auttanut sinua kehittymään ammatillisesti tai henkilökohtaisesti?",
      "Voisitko kuvailla tilannetta, jolloin löysit uuden menetelmän tai prosessin työsi tekemiseen?",
      "Mitkä olivat tavoitteesi hakiessasi tätä työpaikkaa?",
      "Kuinka tavoitteesi ovat muuttuneet?",
      "Kuinka tähänastinen edistymisesi saa sinut tuntemaan?",
      "Mikä inspiroi sinua jatkamaan tavoitteiden saavuttamista?",
      "Kuinka pidät yllä työ- ja vapaa-ajan tasapainoa?",
      "Mitä strategioita käytät pysyäksesi keskittyneenä tavoitteisiisi?",
      "Kuinka määrittelet menestyksen nykyisessä roolissasi?",
      "Voisitko kertoa esimerkin, jolloin kohtasit haasteen ja löysit motivaation voittaa se?",
      "Kuinka pysyt motivoituneena, kun kohtaat kritiikkiä tai negatiivista palautetta?",
      "Mitkä harrastukset tai aktiviteetit auttavat sinua lataamaan akkuja työn ulkopuolella?",
      "Voisitko kuvailla tilannetta, jossa sinun piti motivoida työkaveria tai tiimin jäsentä?",
      "Kuinka etsit mahdollisuuksia henkilökohtaiseen kasvuun ja kehittymiseen?",
      "Mikä on lempipuolesi työssäsi ja miksi?",
      "Kuinka pysyt motivoituneena työskennellessäsi pitkäkestoisissa projekteissa?",
      "Kuinka lähestyt tavoitteiden asettamista, sekä henkilökohtaisesti että ammatillisesti?",
      "Kuinka työsi on mahdollistanut sinulle positiivisen vaikutuksen muihin ihmisiin?",
      "Voisitko kertoa tarinan ajasta, jolloin tunsit olevasi erityisen motivoitunut tai inspiroitunut työssä?",
      "Kuinka pysyt motivoituneena työskennellessäsi tiimissä, jossa on monenlaisia persoonallisuuksia ja tavoitteita?",
      "Kuinka käsittelet takaiskuja tai pettymyksiä työssäsi?",
      "Mikä rooli työtyytyväisyydellä on motivaatiosi ylläpitämisessä?",
      "Kuinka lähestyt uusien taitojen oppimista tai uuden tiedon hankkimista työtäsi varten?",
      "Voisitko kertoa esimerkin, jolloin otit aloitteen työssä saavuttaaksesi positiivisen tuloksen?",
      "Kuinka löydät motivaatiota tilanteissa, joissa työ saattaa olla arkista tai toistuvaa?",
      "Kuinka mittaat menestystäsi ja edistymistäsi urallasi?",
  ])

  const motQuestionsSwe = ([
    "Vad är positivt i ditt arbete?",
    "Vad är annorlunda med ditt arbete nu jämfört med när du började?",
    "Kan du berätta mer om vad du gör i ditt arbete?",
    "Har det hänt något nyligen i ditt arbete?",
    "Hur påverkar dina kollegor ditt sätt att arbeta?",
    "Hur skulle du beskriva din relation med dina kollegor?",
    "Kan du berätta om en gång då du kom på en bra idé i ditt arbete?",
    "Vad gör du för att hantera din tid effektivt på jobbet?",
    "Hur hjälper dina chefer dig att hålla motivationen uppe under arbetet?",
    "Vad tycker du motiverar dig mest i ditt arbete?",
    "Vad fick dig att komma hit idag?",
    "Hur kan jag hjälpa dig att känna dig mer bekväm på jobbet?",
    "Varför är ditt arbete viktigt för dig?",
    "Vad lockade dig till ditt yrke?",
    "Vilka avslappningstekniker har hjälpt dig tidigare?",
    "Hur kände du dig innan du började känna så här om ditt arbete?",
    "Vad tror du skulle hända om du inte gjorde en förändring?",
    "Vad är dagens bästa stund för dig?",
    "Vad får dig att känna dig stöttad i ditt arbete?",
    "Vad har du gjort på jobbet som du är stolt över?",
    "Hur kan du förbättra ditt sätt att arbeta?",
    "Vilka försök har du redan gjort för att förbättra din situation?",
    "Hur skulle en förändring i ditt arbetssätt kunna göra saker annorlunda?",
    "Vilka förändringar skulle hjälpa dig att uppnå dina mål?",
    "Hur har ditt arbete hjälpt dig att utvecklas professionellt eller personligen?",
    "Kan du beskriva en situation där du hittade en ny metod eller process för att göra ditt arbete?",
    "Vilka var dina mål när du ansökte om detta jobb?",
    "Hur har dina mål förändrats?",
    "Hur känns det när du tänker på de framsteg du gjort hittills?",
    "Vad inspirerar dig att fortsätta uppnå dina mål?",
    "Hur upprätthåller du en balans mellan arbete och fritid?",
    "Vilka strategier använder du för att hålla fokus på dina mål?",
    "Hur definierar du framgång i din nuvarande roll?",
    "Kan du ge ett exempel på när du ställdes inför en utmaning och hittade motivationen att övervinna den?",
    "Hur håller du dig motiverad när du möter kritik eller negativ feedback?",
    "Vilka hobbyer eller aktiviteter hjälper dig att ladda batterierna utanför jobbet?",
    "Kan du beskriva en situation där du behövde motivera en kollega eller lagmedlem?",
    "Hur söker du möjligheter till personlig tillväxt och utveckling?",
    "Vad är din favoritdel av ditt arbete och varför?",
    "Hur håller du dig motiverad när du arbetar med långvariga projekt?",
    "Hur närmar du dig att sätta mål, både personligt och professionellt?",
    "Hur har ditt arbete möjliggjort en positiv inverkan på andra människor?",
    "Kan du berätta en historia om en tid när du kände dig särskilt motiverad eller inspirerad på jobbet?",
    "Hur håller du dig motiverad när du arbetar i ett team med olika personligheter och mål?",
    "Hur hanterar du bakslag eller besvikelser i ditt arbete?",
    "Vilken roll har arbetstillfredsställelse i att upprätthålla din motivation?",
    "Hur närmar du dig att lära dig nya färdigheter eller att skaffa ny kunskap för ditt arbete?",
    "Kan du ge ett exempel på när du tog initiativ på jobbet för att uppnå ett positivt resultat?",
    "Hur hittar du motivation i situationer där arbetet kan vara vardagligt eller repetitivt?",
    "Hur mäter du din framgång och dina framsteg i din karriär?"
]);


const [motQuestions, setMotQuestions] =useState([]);
const [fitQuestions, setFitQuestions] =useState([]);

useEffect(() => {

    if (selectedValueTime != ""){
        setSelectedTimeValue(selectedValueTime)
    }
    if (selectedValueLevel != ""){
        setSelectedLevelValue(selectedValueLevel)
    }

    if (kieli === "Suomi"){
        setMotQuestions(motQuestionsFin);
        setFitQuestions(fitQuestionsFin);
    }
    else if (kieli === "Englanti"){
        setMotQuestions(motQuestionsUS);
        setFitQuestions(fitQuestionsUS);

      
    }
    else if (kieli === "Ruotsi"){
        setMotQuestions(motQuestionsSwe);
        setFitQuestions(fitQuestionsSwe);

    }
  
  }, [kieli]); 
  



const [motTitle, setMotTitle] = useState("");

// Modalin tyyli
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '20vw',
      height: '45vh',
      maxWidth: '90vw',
      maxHeight: '80vh',
      padding: '20px',
      textAlign: 'center'
    }
  };

    useEffect(() => {
        
        if (group === "") {
            const currentUrl = window.location.href;
            const parsedUrl = new URL(currentUrl);
            const pathParts = parsedUrl.pathname.split('/');
            if (pathParts.length > 1) {
                setGroup(pathParts[1]); // tallennetaan "prosource" tilaan
            }
        }
      
    }, [group]);

    const toggleQuestionSelection = (question) => {
        setSelectedQuestions((prevSelected) => {
            const isAlreadySelected = prevSelected.includes(question);
            if (isAlreadySelected) {
                // Poistetaan kysymys, jos se on jo valittu ja päivitetään järjestys
                return prevSelected.filter((q) => q !== question);
            } else {
                // Lisätään kysymys valittujen loppuun
                return [...prevSelected, question];
            }
        });
    };

    const addQuestion = (e) => {
        e.preventDefault();
        if (newQuestion.trim()) {
            // Lisää uusi kysymys ja valitse se automaattisesti
            setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
            setSelectedQuestions((prevSelected) => [...prevSelected, newQuestion]);
            setNewQuestion(''); // Tyhjennä lomakekenttä
        }
    };

     // Funktio, jota kutsutaan sähköpostin lähetyksen yhteydessä
     const sendEmail = async () => {
        
        setIsLoading(true);
        
        const encryptedEmailData = CryptoJS.AES.encrypt(JSON.stringify(customerEmail), encryptionKey).toString();
    
        try {
                const response = await fetch('https://us-central1-simudatauser.cloudfunctions.net/api/sendEmail', {
                //const response = await fetch('http://localhost:5001/simudatauser/us-central1/api/sendEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: encryptedEmailData, mandaatti: valittuMandaatti, kieli: kieli, group: group })
            });
    
            const data = await response.json();
            
    
            // Tarkistetaan, että data on onnistunut
            if (data.status === "ok") {
                if (data.hakijat) {
                        // Käydään hakijat läpi ja puretaan sähköpostit sekä 'done' arvo
                        const decryptedHakijat = data.hakijat.map(hakija => {
                            // Varmistetaan, että hakija on objekti ja siinä on 'email' ja 'done' kentät
                            if (hakija.email && hakija.done !== undefined) {  // tarkistetaan myös 'done' kenttä
                                const bytes2 = CryptoJS.AES.decrypt(hakija.email, encryptionKey);
                                let decryptedEmail2 = bytes2.toString(CryptoJS.enc.Utf8);
                    
                                // Poistetaan mahdolliset ylimääräiset lainausmerkit sähköpostin ympäriltä
                                decryptedEmail2 = decryptedEmail2.replace(/^"|"$/g, '').trim();
                    
                                // Varmistetaan, että purettu sähköposti ei ole tyhjä
                                if (decryptedEmail2 !== "") {
                                    // Palautetaan objekti, jossa on 'email' ja 'done'
                                    return { email: decryptedEmail2, done: hakija.done };
                                }
                            }
                            return null;  // Jos sähköposti on tyhjä tai 'done' arvoa ei ole, palautetaan null
                        }).filter(hakija => hakija !== null);  // Poistetaan null-arvot
                    
                        // Tallennetaan purkautuneet hakijat (email ja done) hakijat-taulukkoon
                        setHakijat(decryptedHakijat);
                    
                    
                        // Tyhjennetään asiakassähköposti
                        setCustomerEmail("");
                    }
    
                // Tallennetaan puretut hakijat hakijat-taulukkoon
        
    
                // Voit tehdä jotain muuta onnistuneen lähetyksen jälkeen, kuten näyttää ilmoituksen käyttäjälle
            } else {
                console.error('Sähköpostin lähetyksessä tapahtui virhe:', data);
                // Voit näyttää virheilmoituksen käyttäjälle tässä
            }
        } catch (error) {
            console.error('Virhe sähköpostin lähetyksessä:', error);
            // Voit näyttää virheilmoituksen käyttäjälle tässä
        }
        setIsLoading(false);
        
    };

      // Funktio tarkistamaan sähköpostin oikeellisuuden
      const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Käsitellään sähköpostikentän muutokset
    const handleEmailChange = (e) => {
        const email = e.target.value;
        setCustomerEmail(email);
        if (validateEmail(email)) {
            setEmailError('');
        } else {
            setEmailError('Virheellinen sähköpostiosoite');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateEmail(customerEmail)) {
            sendEmail();
        } else {
            setEmailError('Korjaa sähköpostiosoite ennen tallentamista');
        }
    };


    const getQuestionOrder = (question) => {
        const found = selectedQuestions.find((q) => q.text === question);
        return found ? found.order : null;
    };

    //nappi josta pois
    function homepage(){
        window.location.reload();
      }

    // Tallenna uusi konsultti
    const tallenna = (e) => {
        e.preventDefault();
        if (salasana !== salasanaVahvistus) {
            alert("Salasanat eivät täsmää!");
            return;
        }

        const uusiKonsultti = {
            nimi,
            email,
            salasana,
        };

        setKonsultit([...konsultit, uusiKonsultti]);
        setNimi('');
        setEmail('');
        setSalasana('');
        setSalasanaVahvistus('');
    };

    const saveQuestion = async (event) => {
        event.preventDefault();
        setSaving(true);
        
        fetch('https://us-central1-simudatauser.cloudfunctions.net/api/saveQuestion', {
            //fetch('http://localhost:5001/simudatauser/us-central1/api/saveQuestion', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ questions: selectedQuestions,  mandate:valittuMandaatti, aika:selectedTimeValue, taso:selectedLevelValue })
        })
        .then(response => response.json())
        .then(data => {
            

            // Tarkistetaan, että data on taulukko ja sisältää ainakin yhden elementin
            if (data.status == "ok") {
            try {
               
                setFinalQuestions(data.final);
                setSelectedValueLevel(data.taso);
               setSelectedValueTime(data.aika);
                setSaving(false);
               
            } catch (err) {
                console.error('Virhe JSON:n purkamisessa:', err);
                setSaving(false);
            }
            } else {
            console.error('Data ei ole oikeassa muodossa:', data);
            setSaving(false);
            }
            
        })
        .catch(error => {
            console.error("Virhe kysymysten latauksessa", error);
        });
      
    };

    const loadMand = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setLadattu(false);
        
        fetch('https://us-central1-simudatauser.cloudfunctions.net/api/loadQuestions', {
            //fetch('http://localhost:5001/simudatauser/us-central1/api/loadQuestions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ mandaatti: valittuMandaatti })
        })
        .then(response => response.json())
        .then(data => {
        
    
            // Tarkistetaan, että data on objekti ja sisältää 'questions' ja 'final'
            if (data && data.questions && data.final) {
                try {
                    // Purkataan merkkijonot takaisin taulukoiksi
                    const questions = JSON.parse(data.questions[0]); // Purkaa JSON-merkkijonon taulukoksi
                    //const final = data.final.length > 0 ? JSON.parse(data.final[0]) : []; // Tarkistetaan, että data.final ei ole tyhjä ennen purkamista
              
                    setQuestions(questions);
                    setValittuMandaatti(data.mandaatti);  // Asetetaan kysymykset tilamuuttujaan
                    setKieli(data.kieli);
                    setSelectedValueLevel(data.taso);
                    setSelectedValueTime(data.aika);
                    // Asetetaan final-kysymykset vain, jos data.final ei ole tyhjä
                    if (data.final && data.final.length > 0) {
                        try {
                            const final = JSON.parse(data.final);
                            setFinalQuestions(final);
                        } catch (e) {
                            console.error("JSON parsing error:", e);
                            setFinalQuestions(""); // Jos JSON on virheellinen, asetetaan tyhjä arvo
                        }
                    } else {
                        setFinalQuestions(""); // Jos data.final on tyhjä tai ei ole määritelty
                    }
                
                    // Tarkistetaan, onko data.hakijat olemassa ja asetetaan se, jos on
                    if (data.hakijat) {
                        // Käydään hakijat läpi ja puretaan sähköpostit sekä 'done' arvo
                        const decryptedHakijat = data.hakijat.map(hakija => {
                            // Varmistetaan, että hakija on objekti ja siinä on 'email' ja 'done' kentät
                            if (hakija.email && hakija.done !== undefined) {
                             
                                const bytes2 = CryptoJS.AES.decrypt(hakija.email, encryptionKey);
                                let decryptedEmail2 = bytes2.toString(CryptoJS.enc.Utf8);
                            
                    
                                // Poistetaan mahdolliset ylimääräiset lainausmerkit sähköpostin ympäriltä
                                decryptedEmail2 = decryptedEmail2.replace(/^"|"$/g, '').trim();
                    
                                // Varmistetaan, että purettu sähköposti ei ole tyhjä
                                if (decryptedEmail2 !== "") {
                                    // Palautetaan objekti, jossa on 'email' ja 'done'
                                    return { email: decryptedEmail2, done: hakija.done };
                                }
                            }
                            return null;  // Jos sähköposti on tyhjä tai 'done' arvoa ei ole, palautetaan null
                        }).filter(hakija => hakija !== null);  // Poistetaan null-arvot
                    
                        // Tallennetaan purkautuneet hakijat (email ja done) hakijat-taulukkoon
                        setHakijat(decryptedHakijat);
                
                    
                        // Tyhjennetään asiakassähköposti
                        setCustomerEmail("");
                    }
                
                    setIsLoading(false);
                    setLadattu(true);
                } catch (err) {
                    console.error('Virhe JSON:n purkamisessa:', err);
                }
            } else {
                console.error('Data ei ole oikeassa muodossa:', data);
            }
        })
        .catch(error => {
            console.error("Virhe kysymysten latauksessa", error);
        });
    };
    
    

    if (!ladattu){

        
        return (
            <div>
                {/* Navigation Icon Container */}
                <div style={{ position: "absolute", top: "5vh", right: "10vh", zIndex: 1000 }}>
                    <Nav.Link onClick={() => homepage()} className="d-none d-md-block">
                        <VscSignOut style={{ fontSize: "1.9em" }} />
                    </Nav.Link>
                </div>
        
                <Container style={{ padding: "10px", height: "95vh",  }}>
                    <img className="FormLogo" src={Logo} style={{ height: "5vh", marginTop: "1vh" }} alt="Logo" />
                    <h1 className="FormTitle" style={{ fontSize: "1.95em", marginBottom: "2vh" }}>Konsulttisivu</h1>
                    <Row className="justify-content-start" style={{ width: "100%", height: "70vh" }}>
                        
                        
                        <Col md={4} style={{ display: "flex", justifyContent: "center", marginLeft:"30%" }}>
                            <Card className="Card" style={{ width: "100%", height: "90%", margin: "auto" }}>
                                <Card.Body style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                                    <Form onSubmit={tallenna}>
                                        
                                    {isLoading ? (
                                        <div style={{ textAlign: "center" }}>
                                            <img className="logopikku" src={Logopikku} alt="kuva" />
                                     
                                            <div>
                                            <p>Loading...</p>
                                            </div>
                                            
                                         
                                        </div>
                                        ) : (
                                            <div>

                                                <div className="form-group" style={{marginTop:"5vh"}}>
                                                    <label style={{marginBottom:"5vh"}}>Valitse mandaatti:</label>
                                                    <select value={valittuMandaatti} onChange={(e) => setValittuMandaatti(e.target.value)}>
                                                        <option value="">{isLoading ? "Loading..." : "Valitse mandaatti"}</option>
                                                        {!isLoading && mandaatit.map((mandaatti, index) => (
                                                            <option key={index} value={mandaatti}>{mandaatti}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                        
                                                <div style={{ display: "flex", marginTop: "10vh", justifyContent: "space-between" }}>
                                                    <Button
                                                        className="SignIn"
                                                        variant="primary"
                                                        type="button"
                                                        style={{ flexGrow: 1, marginLeft: "auto", marginRight:"auto", marginTop: "10vh", maxWidth: "200px" }} // Lisätty maxWidth
                                                        onClick={(e) => loadMand(e)}
                                                        disabled={(mandaatit[0] || mandaatit[1]) === "ei löytynyt mandaattia"}
                                                    >
                                                        Lataa
                                                    </Button>
                                                </div>

                                            </div>
                                        
                                        )}

                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }else if (ladattu) {
        return (
           
            <Container fluid style={{ marginTop: '3vh',backgroundColor: '#f7f7f7', }}>
                
                <Row className="justify-content-center">
                    {/* Vasemman puolen Final Questions -lista */}
                    <Col md={3} style={{ marginTop: "2vh" }}>
                        <Card className="p-3 shadow-sm">
                            <Card.Title>Haastattelukysymykset</Card.Title>
                        <p>Arviontitaso: {selectedValueLevel}</p>
                        <p>Vastausaika: {selectedValueTime}</p>
                            <Card.Body style={{ maxHeight: '65.5vh', overflowY: 'auto', marginTop:"-3vh" }}>
                                {saving ? (
                                    // Kun saving on true, näytetään loading ja kuva
                                    <div style={{ textAlign: "center" }}>
                                        <img className="logopikku" src={Logopikku} alt="kuva" />
                                        <div>
                                            <p>Loading...</p>
                                        </div>
                                    </div>
                                ) : finalQuestions && finalQuestions.length > 0 ? (
                                    // Kun saving on false ja kysymykset löytyvät, näytetään ne
                                    finalQuestions.map((question, index) => (
                                        <div key={index} style={{ padding: '5px 0' }}>
                                            {index + 1}. {question}
                                        </div>
                                    ))
                                ) : (
                                    // Jos saving on false mutta ei kysymyksiä, näytetään "No questions available"
                                    <div>No questions available</div>
                                )}
                        
                            </Card.Body>
                        </Card>
                        
                            <div className="d-flex flex-row justify-content-center gap-3 p-4" style={{ width: "100%", minWidth: "400px", marginLeft: "-2vh" }}>
                                <div style={{ width: "200px" }}>
                                    <label htmlFor="dropdown-time" className="mb-2 d-block text-center" style={{ marginTop: "-3vh" }}>Ajan valinta</label>
                                    <Dropdown onSelect={(eventKey) => setSelectedTimeValue(eventKey)}>
                                        <Dropdown.Toggle variant="primary" id="dropdown-time" style={{ width: "100%" }}>
                                            {selectedTimeValue || "Valitse aika"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="90">90 sekuntia</Dropdown.Item>
                                            <Dropdown.Item eventKey="120">120 sekuntia</Dropdown.Item>
                                            <Dropdown.Item eventKey="150">150 sekuntia</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div style={{ width: "200px" }}>
                                    <label htmlFor="dropdown-level" className="mb-2 d-block text-center" style={{ marginTop: "-3vh" }}>Tason valinta</label>
                                    <Dropdown onSelect={(eventKey) => setSelectedLevelValue(eventKey)}>
                                        <Dropdown.Toggle variant="primary" id="dropdown-level" style={{ width: "100%" }}>
                                            {selectedLevelValue || "Valitse taso"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey="Helppo">Helppo</Dropdown.Item>
                                            <Dropdown.Item eventKey="Keskitaso">Keskitaso</Dropdown.Item>
                                            <Dropdown.Item eventKey="Vaikea">Vaikea</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                     
                         {/* Navigation Icon Container */}
                         
                    </Col>

    
                    {/* Keskimmäinen kysymyslista ja lomake */}
                    <Col md={6} style={{marginTop:"2vh"}}>
                        <Card className="p-3 shadow-sm">
                            <Card.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
                                <h3 style={{marginBottom:"3vh"}}>{gptTitle}</h3>
                                {questions.map((question, index) => {
                                    const questionOrder = selectedQuestions.indexOf(question) + 1;
                                    const isBold = questionOrder > 0 || index < 5; // Boldataan joko valitut kysymykset tai viisi ensimmäistä
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                fontWeight: isBold ? 'bold' : 'normal',
                                                cursor: 'pointer',
                                                padding: '5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                checked={questionOrder > 0}
                                                onChange={() => toggleQuestionSelection(question)}
                                                className="me-2"
                                            />
                                            <span onClick={() => toggleQuestionSelection(question)}>
                                                {questionOrder > 0 ? `${questionOrder}. ` : ''}{question}
                                            </span>
                                        </div>
                                    );
                                })}
                                <h3 style={{marginBottom:"3vh", marginTop:"5vh"}}>Motivaatio</h3>
                                {motQuestions.map((question, index) => {
                                    const questionOrder = selectedQuestions.indexOf(question) + 1;
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                fontWeight: questionOrder ? 'bold' : 'normal',
                                                cursor: 'pointer',
                                                padding: '5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                        
                                                
                                            }}
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                checked={questionOrder > 0}
                                                onChange={() => toggleQuestionSelection(question)}
                                                className="me-2"
                                            />
                                            <span onClick={() => toggleQuestionSelection(question)}>
                                                {questionOrder > 0 ? `${questionOrder}. ` : ''}{question}
                                            </span>
                                        </div>
                                    );
                                })}
                                 <h3 style={{marginBottom:"3vh", marginTop:"5vh"}}>Sopivuus</h3>
                                {fitQuestions.map((question, index) => {
                                    const questionOrder = selectedQuestions.indexOf(question) + 1;
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                fontWeight: questionOrder ? 'bold' : 'normal',
                                                cursor: 'pointer',
                                                padding: '5px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                
                                            }}
                                        >
                                            <Form.Check
                                                type="checkbox"
                                                checked={questionOrder > 0}
                                                onChange={() => toggleQuestionSelection(question)}
                                                className="me-2"
                                            />
                                            <span onClick={() => toggleQuestionSelection(question)}>
                                                {questionOrder > 0 ? `${questionOrder}. ` : ''}{question}
                                            </span>
                                        </div>
                                    );
                                })}
    
                                {/* Lomake uuden kysymyksen lisäämiseen */}
                                
                            </Card.Body>
                        </Card>
                        <Form onSubmit={addQuestion} className="mt-3 d-flex align-items-center">
                                    <Form.Control
                                        type="text"
                                        value={newQuestion}
                                        onChange={(e) => setNewQuestion(e.target.value)}
                                        placeholder="Kirjoita oma kysymyksesi"
                                        className="me-2"
                                    />
                                    <Button variant="primary" type="submit" className="me-2">Lisää</Button>
                                    <Button 
                                        variant="secondary" 
                                        type="button" 
                                        onClick={saveQuestion} 
                                        disabled={!selectedLevelValue || !selectedTimeValue || selectedQuestions.length < 1}
                                        className="bg-green-500 text-white hover:bg-green-600 disabled:bg-gray-300"
                                        >
                                        Tallenna
                                        </Button>
                                </Form>
                    </Col>
    
                    {/* Oikean puolen hakijat-lista */}
                    <Col md={3} style={{ marginTop: "2vh" }}>
                        <div style={{ position: "absolute", top: "7vh", right: "5vh", zIndex: 1000 }}>
                            <Nav.Link onClick={() => homepage()} className="d-none d-md-block">
                                <VscSignOut style={{ fontSize: "1.9em" }} />
                            </Nav.Link>
                        </div>
                    <Card className="p-3 shadow-sm">
                        <Card.Title>Hakijat</Card.Title>
                        <Card.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>

                            {isLoading ? (
                                // Kun saving on true, näytetään loading ja kuva
                                <div style={{ textAlign: "center" }}>
                                    <img className="logopikku" src={Logopikku} alt="kuva" />
                                    <div>
                                        <p>Loading...</p>
                                    </div>
                                </div>
                            ) : (
                                hakijat.map((item, index) => (
                                    <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <span style={{ marginRight: '10px' }}>{item.email}</span>
                                        
                                        {/* Värikoodattu teksti ja ikoni */}
                                        <span
                                            style={{
                                                color: item.done ? 'green' : 'red',
                                                fontWeight: 'bold',
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {item.done ? (
                                                <>
                                                    <FaCheck style={{ marginRight: '5px' }} /> 
                                                </>
                                            ) : (
                                                <>
                                                    <FaTimes style={{ marginRight: '5px' }} /> 
                                                </>
                                            )}
                                        </span>
                                    </li>
                                ))
                            )}

                            {/* Sähköpostikenttä ja Tallenna-nappi */}
                            <Form onSubmit={handleSubmit} className="mt-3">
                                <Form.Group controlId="customerEmail">
                                    <Form.Control
                                        type="email"
                                        value={customerEmail}
                                        onChange={handleEmailChange}
                                        placeholder="Sähköpostiosoite"
                                        isInvalid={!!emailError}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {emailError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="mt-2"
                                    disabled={!!emailError || finalQuestions.length < 1}
                                >
                                    Lähetä kutsu
                                </Button>
                            </Form>
                            
                        </Card.Body>
                        
                    </Card>
                    <Button
                        variant="danger"
                        type="submit"
                        className="mt-2" 
                        onClick={openModal} // Avaa vahvistusmodaali 
                        >
                        Poista mandaatti
                    </Button>

                    {/* Vahvistusmodaali */}
                        <Modal
                            isOpen={isModalOpen}
                            onRequestClose={closeModal}
                            contentLabel="Vahvistus"
                            style={customStyles}
                        >
                            <h2>Oletko varma?</h2>
                            <p>Haluatko varmasti poistaa mandaatin?</p>
                            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '5vh', marginBottom: '5vh' }}>
                            <Button variant="success" onClick={handleConfirm}>Kyllä</Button>
                            <Button variant="danger" onClick={closeModal}>Peruuta</Button>
                            </div>
                        </Modal>
                </Col>

                </Row>
                
            </Container>
        );
        
        
        
    }
    
};

export default ConMan;