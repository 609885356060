import { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import './App.css';
import { signInWithGoogle, handleGoogleSignInRedirect } from './googleSignIn';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import Logopikku from "./logopikku.png";
import Logo from "./logo.png";
import Pro from "./Pro2.jpg";
import ConAdd from "./ConAdd";


function AuthenticationForm2({ onAddLogin, onaddKonsultit, onAddMandaatit }) {
    const [isLoginForm, setIsLoginForm] = useState(true);
    const [email, setEmail] = useState("");
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [forgot, setForgot] = useState(false);
    const [finland, setFinland] = useState(false);
    const [logIn, setLogIn] = useState(false);
    const [newpass, setNewpass] = useState(false);
    const [konsultit, setKonsultit] = useState([]);
    const [mandaatit, setMandaatit] = useState([]);
    const [titles, setTitles] = useState({signin: "Sign in",
    signup: "Sign up",
    forgot: "Forgot your password?",
    email: "Email",
    password:"Password",
    update: "Password update",
    placeemail: "Your email",
    yourpass: "Your password",
    createpass: "Create password",
    submit: "submit",
    new: "New password",
    loading: "Loading..."})

    const titlesFi = {
      signin: "Kirjaudu sisään",
        signup: "Rekisteröidy",
        forgot: "Unohditko salasanasi?",
        email: "Sähköposti",
        password:"Salasana",
        update: "Salasanan uusiminen",
        placeemail: "Sinun sähköpostiosoite",
        yourpass: "Sinun salasanasi",
        createpass: "Luo uusi salasana",
        submit: "Lähetä",
        new: "Uusi salasana",
        loading: "Hetkinen...",
        id : "Käyttäjätunnus",
        id2 : "Kirjaudu sisään käyttäjätunnuksella"
      }

      const titlesEng = {
        signin: "Sign in",
        signup: "Sign up",
        forgot: "Forgot your password?",
        email: "Email",
        password:"Password",
        update: "Password update",
        placeemail: "Your email",
        yourpass: "Your password",
        createpass: "Create a new password",
        submit: "submit",
        new: "New password",
        loading: "Loading...",
        id : "User ID",
        id2 : "Use your user ID to log in"
        }

      

    const [info, setInfo] = useState('');
    const [info2, setInfo2] = useState('');

    useEffect(() => {
      
      if (konsultit.length > 0) {
        onAddLogin(true);
      
      }
    }, [konsultit]);

    

    useEffect(() => {
      // Hanki nykyinen URL
      if (name == ""){
  
  
      const currentUrl = window.location.href;
  
      // Luo URL-objekti ja jaa polku
      const parsedUrl = new URL(currentUrl);
      const pathParts = parsedUrl.pathname.split('/');
  
      // Ota haluttu osa (tässä tapauksessa prosource)
      if (pathParts.length > 1) {
        setName(pathParts[1]); // tallennetaan "prosource" tilaan
      }
      }
   
    }, [name]);

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleSend(e);
      }
      else if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation(); 
        // Voit myös tehdä muita toimintoja tässä, jos haluat
      }
    };
    const handleKeyDown2 = (e) => {
      if (e.key === 'Enter') {
        handleSend(e);
      }
      else if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation(); 
        // Voit myös tehdä muita toimintoja tässä, jos haluat
      }
    };
    
      const handleSend = async (event) => {


        // Jos kaikki tarkistukset menevät läpi, tyhjennä info ja jatka lomakkeen lähettämistä
        setInfo("");
        setInfo2("");

        event.preventDefault();
        
        setIsLoading(true);
          //fetch('http://localhost:5001/simudatauser/us-central1/api/managment', {
            fetch('https://us-central1-simudatauser.cloudfunctions.net/api/managment', {
              //fetch('http://localhost:5001/simudatauser/us-central1/api/addNames', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: email, pass:password, login:isLoginForm, lan:finland, name:name})
        })
  
        .then(response => response.json())
        .then(data => { 
      
            setIsLoading(false);
            
            if (data.status == "tryout" || data.status == "member" ){
 
              sessionStorage.setItem('email', email);
     
            }else if (data.status == "väärä passu"){
              if(!finland){
                setInfo("väärä passu");
              setInfo2("väärä passu");
              }else{
                setInfo("väärä passu");
              setInfo2("väärä passu");
              }
            }else if (data.status == "ok"){
              if(!finland){
               onAddMandaatit(data.lois, data.name);
              }else{            
                onAddMandaatit(data.lois, data.name);
              }
            }
            else if (data.status == "väärä id"){
              if(!finland){
                setInfo("väärä id");
              setInfo2("väärä id");
              }else{
                setInfo("väärä id");
              setInfo2("väärä id");
              }
            }
            else if (data.status == "ok tyhjä"){
           
              const temp = ["Ei lötynyt mandaattia"];
              if(!finland){
                
                onAddMandaatit(temp, data.name);
              }else{
                onAddMandaatit(temp, data.name);
              }
            }
            else if (data.status == "pass true"){
              if(!finland){
                setInfo("pass true");
              setInfo2("pass true");
              }else{
                setInfo("pass true");
              setInfo2("pass true");
              }
            }else if (data.status == "login"){
              if(!finland){
           
                setKonsultit(data.konsultit);
                onaddKonsultit(data.konsultit);
           
              }else{          
                
                setKonsultit(data.konsultit);
                onaddKonsultit(data.konsultit);
         
                
              }
            }
            else {
              if(!finland){
              setInfo("Something went wrong, please contact Lassi.");
              setInfo2("Something went wrong, please contact Lassi.");
              }else {
                setInfo("Jotain meni pieleen, ole hyvä ja ota yhteyttä Lassiin.");
                setInfo2("Jotain meni pieleen, ole hyvä ja ota yhteyttä Lassiin.");
              }
            }
          }
        ) 
        .catch(error => console.error(error));  

       setInfo("");
      setInfo2("");
        
        //setInfo2("Coming soon!");
      };


      //Google signin if needed
      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (user) {
            setEmail(user.email);
          } else {
            setEmail("");
          }
        });

        const domain = window.location.hostname;

        if (domain === 'theinterviewsimulator.com' || domain === 'www.theinterviewsimulator.com') {
          setFinland(false);
          setTitles(titlesEng);
        } else {
          setFinland(true);
          setTitles(titlesFi);
        }
      
        // Cleanup function, which removes the user state listener.
        return () => unsubscribe();
      }, []);
    
      
      useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape') {
            event.preventDefault();
          }
        };
        const handleKeyDown2 = (event) => {
          if (event.key === 'Escape') {
            event.preventDefault();
          }
        };
    
    
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keydown', handleKeyDown2);
        
    
        // Komponentin poistuessa, poista tapahtumankäsittelijä
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
          window.removeEventListener('keydown', handleKeyDown2);
        };
      }, []);

      const handleClosee = () => {
        setInfo2(""); // Nollaa info2:n arvo
      };

      const myRef = useRef();

      useEffect(() => {
        myRef.current.focus();
      }, []);
  
    if (!isLoading && !forgot && info2 == "" && !logIn){
      return (
        <div >
          <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding:"10px",
            
          }}
        >
          <img className="FormLogo" src={Pro} style={{height:"20vh", marginTop:"0vh", marginBottom:"0vh"}}></img>
          <h1  className="FormTitle" style={{fontSize:"1.95em", marginBottom:"1vh"}}>Management page</h1>
          <h3 style={{fontSize:"1.1em"}}>{isLoginForm ? titles.signin : titles.signin}</h3>
          <Row
            className="justify-content-md-center"
            style={{
              width: "100%",
              height: "55vh",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Col>
              <Card className='Card' style={{ width: "100%", height: "32vh" }}>
                <Card.Body
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    borderRadius:"0"
                  }}
                >
                  <Form >
                   
                  <Form.Group>
                    <Form.Label htmlFor="email">{titles.id}</Form.Label>
                    <Form.Control
                      id="email"
                      type="email"
                      ref={myRef}
                      placeholder={titles.id2}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                    <Form.Label htmlFor="password" style={{ paddingTop: "1vh" }}>
                      {titles.password}
                    </Form.Label>
                    <Form.Control
                      id="password"
                      type="password"
                      placeholder={isLoginForm ? titles.yourpass : titles.yourpass}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={handleKeyDown}
                    />
                  </Form.Group>
                    
                  </Form>
                  <Button
                      className='SignIn'
                      variant="primary"
                      type="submit"
                      
                      style={{marginTop:"0vh", width:"40%", marginRight:"auto", marginLeft:"auto"}}
                      onClick={(e)=> handleSend(e)}
                      >
                      {isLoginForm ? titles.signin : titles.signin}
                    
                  </Button>
                </Card.Body>
              </Card>
              
              <div className='formInfo'>{info}</div>
            </Col>
            <p className="support">support@lassialbin.com</p>
          </Row>
         
        </Container>
        </div>
      )
    }
    else if (logIn){

      return (
        <div>
         {logIn && konsultit.length > 0 && (
            <div>
              <ConAdd lan={finland} kon={konsultit} />
            </div>
          )}
        </div>

      );
    }
    else {
      return (

        <div style={{ textAlign: "center" }}>
          <img className="logopikku" src={Logopikku} alt="kuva" />
          {info2 == "" && (
            <div>
          <p>{titles.loading}</p>
          </div>
          )}
          {info2 !== "" && (
            <div>
              <p>{info2}</p>
              
              <Button
                    className='SignIn'
                    variant="primary"
                    type="submit"
                    style={{marginTop:"0vh", width:"40%", marginRight:"auto", marginLeft:"auto"}}
                    onClick={(e)=> handleClosee(e)}
                    >
                    {finland ? <p>Sulje</p> : <p>Close</p>}
                  
                </Button>
            </div>
          )}
        </div>
      );
    }
    ;}

export default AuthenticationForm2;
